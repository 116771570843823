import React              from "react";
import PropTypes          from 'prop-types';
import ReactDOM           from "react-dom";
import LogoLoader         from "./Loader/LogoLoader";
import Flux               from '../flux/Flux';
import {currentYPosition} from '../utils/CommonUtils';

const body = document.body;

class Modal extends React.Component {

	constructor(props) {
		super(props);

		this.scrollY   = '';
		this.isRemoved = false;

		this.state = {
			modalElement: document.createElement('div'),
			show: this.props.animation.useAnimation ? false : true,
		};
		body.appendChild(this.state.modalElement);
		!this.props.animation.useAnimation && this.addPreventScrolling();

		this.removeModal            = this.removeModal.bind(this);
		this.onCloseClick           = this.onCloseClick.bind(this);
		this.onContainerClick       = this.onContainerClick.bind(this);
		this.containerIsInitialized = this.containerIsInitialized.bind(this);
		this.onKeyUp                = this.onKeyUp.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addShouldModalWindowCloseListener(this.onCloseClick);
		if (!this.props.hideCloseButton) {
			document.addEventListener('keyup', this.onKeyUp);
		}

		if (this.props.animation.useAnimation) {
			setTimeout(() => {
			  this.setState({show: true}, () => {
				setTimeout(() => {
					this.addPreventScrolling();
				}, 500);
			  });
			}, 100);
		}
	}

	componentWillUnmount() {
		if (!this.props.hideCloseButton && this.state.modalElement) {
			document.removeEventListener('keyup', this.onKeyUp);
		}

		if (!this.props.renderInline) {
			this.removeModal();
		}
	}

	addPreventScrolling() {
		if (document.querySelector('.prevent-scrolling') === null) {
			this.scrollY = currentYPosition();
			document.querySelector('body').classList.add('prevent-scrolling');
			if (document.querySelector('html.mobile') !== null && Flux.Browser.isSafariMobile()) {
				document.querySelector('html.mobile').classList.add('prevent-scrolling'); // ios safari fix
			}
		}
	}

	removePreventScrolling() {
		if (document.querySelector('.prevent-scrolling') !== null) {
			document.querySelector('body').classList.remove('prevent-scrolling');
			if (document.querySelector('html.mobile') !== null) {
				document.querySelector('html.mobile').classList.remove('prevent-scrolling');
			}
		}
	}

	containerIsInitialized(container) {
		if (this.props.postRenderCallback) {
			this.props.postRenderCallback(container);
		}
	}

	removeModal() {
		if (!this.isRemoved) {
			this.isRemoved = true;

			if (this.state.modalElement) {
				!this.props.animation.useAnimation && this.removePreventScrolling();

				if (typeof this.props.beforeClose === 'function') {
					this.props.beforeClose();
				}

				this.setState({
					modalElement: null,
				});

				if (typeof this.props.onClose === 'function') {
					this.props.onClose();
				}
			} else {
				if (typeof this.props.beforeClose === 'function') {
					this.props.beforeClose();
				}

				if (typeof this.props.onClose === 'function') {
					this.props.onClose();
				}
			}
		}
	}

	getModal() {
		const divProps       = {...this.props.divProps};
		let styles           = {};
		let containerClasses = 'modal__container ' + this.props.className;
		let modalClasses     = 'modal ' + this.props.modalClassName + (this.props.isHidden ? ' h-hide' : '');

		if (this.props.animation.useAnimation && this.props.animation.fromRight && !this.state.show) {
			modalClasses += " modal--transform-from-right";
		}

		if (this.props.containerWidth && !this.props.isHls && !this.props.isFullHeight) {
			containerClasses += ' -auto-size';

			if (this.props.useMaxWidth) {
				styles = {
					maxWidth: this.props.containerWidth + 'px',
					height:   this.props.containerHeight + 'px',
				};
			} else {
				styles = {
					width:  this.props.containerWidth + 'px',
					height: this.props.containerHeight + 'px',
				};
			}
		}

		if (this.props.dynamicSize) {
			containerClasses += ' -dynamic-size';
			modalClasses += ' -dynamic-size';

			if (this.props.containerHeight) {
				styles.height = this.props.containerHeight + 'px';
			} else {
				styles.height = '';
			}

			if (this.props.containerWidth && !this.props.useMaxWidth) {
				styles.width = this.props.containerWidth + 'px';
			}
		}

		return (<div className={modalClasses} {...divProps} onClick={this.onContainerClick}>
			<div className={containerClasses} style={styles} ref={element => this.containerIsInitialized(element)}>
				{this.props.showSpinner && <div className="modal__spinner">
					<LogoLoader />
				</div>}
				<div className="modal__frame">
					{!this.props.hideCloseButton && <span className={'icon -icon-close-line modal__btn-close'}
					                                      onClick={this.onCloseClick}
					/>}
					<div className={"modal__content" + (this.props.useFlexDirection ? ' modal__content--flex' : '')}>{this.props.children}</div>
				</div>
			</div>
		</div>);
	}

	onCloseClick(e) {
		if (e && e.stopPropagation) {
			e.stopPropagation();
		}

		if (this.props.animation.useAnimation) {
			this.setState({show: false}, () => {
				this.removePreventScrolling();
				if (typeof this.props.beforeAnimationClose === 'function') {
					this.props.beforeAnimationClose();
				}
				setTimeout(() => this.removeModal(), 500);
			});
		} else {
			this.removeModal();
		}
	}

	onContainerClick(e) {
		if (e.target && e.target.classList && e.target.classList.contains('modal')) {
			if ((typeof this.props.onContainerClickFn === 'function' && this.props.hideCloseButton)) {
				this.props.onContainerClickFn();
			} else if (!this.props.hideCloseButton) {
				this.props.isContainerClickClose && this.removeModal();
			}
		}
	}

	onKeyUp(e) {
		switch (e.keyCode) {
			// escape
			case 27:
				// call functions before remove modal, to prevent error while unmouting
				if (!this.props.hideCloseButton) {
					this.removeModal();
				}
				break;
			default:
				break;
		}
	}

	render() {
		return this.props.renderInline ? this.getModal() : (this.state.modalElement ? ReactDOM.createPortal(this.getModal(), this.state.modalElement) : null);
	}
}

Modal.propTypes = {
	beforeClose:           PropTypes.func,
	children:              PropTypes.node,
	className:             PropTypes.string,
	modalClassName:        PropTypes.string,
	containerWidth:        PropTypes.number,
	containerHeight:       PropTypes.number,
	dynamicSize:           PropTypes.bool,
	hideCloseButton:       PropTypes.bool,
	isHidden:              PropTypes.bool,
	onClose:               PropTypes.func,
	beforeAnimationClose:  PropTypes.func,
	onContainerClickFn:    PropTypes.func,
	showSpinner:           PropTypes.bool,
	useMaxWidth:           PropTypes.bool,
	postRenderCallback:    PropTypes.func,
	renderInline:          PropTypes.bool,
	isHls:                 PropTypes.bool,
	useFlexDirection:      PropTypes.bool,
	isFullHeight:          PropTypes.bool,
	isContainerClickClose: PropTypes.bool,
	animation:             PropTypes.shape({
		useAnimation: PropTypes.bool,
		fromRight:    PropTypes.bool,
	}),
	divProps:             PropTypes.object,
};

Modal.defaultProps = {
	beforeClose:           null,
	beforeAnimationClose:  null,
	className:             '',
	modalClassName:        '',
	dynamicSize:           false,
	hideCloseButton:       false,
	isHidden:              false,
	onContainerClickFn:    () => {
	},
	showSpinner:           false,
	useMaxWidth:           false,
	postRenderCallback:    null,
	renderInline:          false,
	isHls:                 false,
	useFlexDirection:      false,
	isFullHeight:          false,
	isContainerClickClose: true,
	animation:             {},
	divProps:              {},
};

export default Modal;
